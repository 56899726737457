import React from "react";
import ElementListing from "./elementListing";

class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClickedElement = this.handleClickedElement.bind(this);
  }

  /**
   * @param {*} elem  objet envoyé depuis elementListing {
      family: string,
      id: id,
      category: obj,
    }
   * Envoie vers la page produit l'objet pour enregistrment dans le tab
   */
  handleClickedElement(elem) {
    this.props.onHandleClickedElement(elem);
  }

  render() {
    const elementListing = this.props.families.map((element, key) => {
      return (
        <div className="col bg-neutral-100">
          <ElementListing
            family={element.name}
            key={key}
            categories={element.categories}
            image={require("../images/" + element.name + ".png")}
            onHandleClickedElement={this.handleClickedElement}
          ></ElementListing>
        </div>
      );
    });

    return (
      <div
        className="row"
        style={{ placeItems: "flex-start", scrollBehavior: "auto" }}
      >
        {elementListing}
      </div>
    );
  }
}

export default Listing;
