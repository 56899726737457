import React from "react";
import { Input, Button } from "reactstrap";

class LigneTableau extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this);
    this.timeConvert = this.timeConvert.bind(this);
  }

  /**
   * @param {*} e nombre renseigné dans l'input de quantité
   * Assigne la quantité à la bonne catégorie et renvoie la catégorie (obj) vers la page produit pour enregistrement dans le tab
   */
  handleQuantityChange(e) {
    let catToUpdate = this.props.category;
    catToUpdate.quantity = Number(e.target.value);
    this.props.onChangeQuantity(catToUpdate);
  }

  /**
   * Envoie la catégorie de la ligne vers la page produit pour être effacée
   */
  handleOnClickRemove() {
    this.props.onRemoveLine(this.props.category);
  }

  /**
   * @param {*} time temps sous forme décimale
   * Convertit le nombre décimal en h/min
   */
  timeConvert(time) {
    var hours = Math.floor(time);
    var minutes = (time - hours) * 60;
    var rminutes = Math.round(minutes);
    if (hours === 0) {
      return rminutes + " minutes";
    } else {
      return hours + " heure(s) et " + rminutes + " minutes";
    }
  }

  render() {
    return (
      <tr id={this.props.id}>
        <th>{this.props.name}</th>
        <td>
          <Input
            key="unique"
            className="my-custom-class"
            bsSize="sm"
            type="number"
            min={1}
            value={Number(this.props.quantity).toString()}
            onChange={this.handleQuantityChange}
            style={{
              borderRadius: "25px",
              width: "60%",
              marginLeft: "1rem",
              fontSize: "10px",
            }}
          />
        </td>
        <td>
          {(this.props.category.averageWeight * this.props.quantity) / 1000} kg
        </td>
        <td>{this.timeConvert(this.props.category.totalTime)}</td>
        <td></td>
        <td>
          <Button
            size="sm"
            onClick={this.handleOnClickRemove}
            style={{ border: "none" }}
          >
            <img
              style={{ width: "20px", heigth: "20px" }}
              alt="remove button"
              src={require("../images/trash.png")}
            />
          </Button>
        </td>
      </tr>
    );
  }
}

export default LigneTableau;
