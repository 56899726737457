import React from "react";

class TitreEtInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSaveChoices = this.handleSaveChoices.bind(this);
    this.handleElementCertifie = this.handleElementCertifie.bind(this);
    this.handleConditionnement = this.handleConditionnement.bind(this);
    this.handleAdresse = this.handleAdresse.bind(this);
  }

  /**
   * @param {*} obj objet correspondant à l'option choisie (conditionnement, Bilan RSE, Effacement Certifié). Structure : {
      type: string,
      param: bolean,
    }
   * @param {*} objEffCertiToUpdate 
   * Envoie l'objet dans la page option via la méthode saveObjetRecap
   */
  handleSaveChoices(obj, objEffCertiToUpdate) {
    this.props.saveObjetRecap(obj, objEffCertiToUpdate);
  }

  /**
   * @param {*} nombre nombre d'éléments à certifier
   * Envoie le chiffre dans la page option via la éthode onElementCertifie
   */
  handleElementCertifie(nombre) {
    this.props.onElementCertifie(nombre);
  }

  /**
   * @param {*} nombre nombre de palettes
   * Envoie le chiffre dans la page option via la éthode onAllConditionnement
   */
  handleConditionnement(nombre) {
    this.props.onAllConditionnement(nombre);
  }

  /**
   * @param {*} region obj contenant la region choisie par l'utilisateur. Structure : adresse = {
        regionLongName: string,
        adresseToPrint: string,
      }
   * Envoie l'objet dans l'élément parent via la méthode onAdresse
   */
  handleAdresse(region) {
    this.props.onAdresse(region);
  }

  render() {
    return (
      <div
        className="inline pb-4"
        style={{ fontSize: "15px" }}
        id={this.props.id}
      >
        <h2
          className="h2 align"
          style={{ paddingTop: "1.6rem", fontWeight: "bolder" }}
        >
          {this.props.name}
        </h2>
        <this.props.component
          objetRecap={this.props.objetRecap}
          saveObjetRecap={this.handleSaveChoices}
          onElementCertifie={this.handleElementCertifie}
          onAllConditionnement={this.handleConditionnement}
          nombreElementCertifie={this.props.nombreElementCertifie}
          nombrePalettes={this.props.nombrePalettes}
          onAdresse={this.handleAdresse}
          nombreAdvElementCert={this.props.nombreAdvElementCert}
          nombreAdvRSE={this.props.nombreAdvRSE}
          recapInfo={this.props.recapInfo}
        />
      </div>
    );
  }
}

export default TitreEtInput;
