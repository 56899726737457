import React from "react";
import { Input } from "reactstrap";

class LigneTableauRecherche extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchProduct: "",
      filteredCategories: [],
    };

    this.handleChangeSearchProduct = this.handleChangeSearchProduct.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  /**
   * @param {*} e élément entré dans l'input (string)
   * Entre dans le tableau de séléction de recherche les catégories correspondantes à ce que l'utilisateur tape dans l'input
   */
  handleChangeSearchProduct(e) {
    let str = e.target.value;
    let filtered = [];
    this.props.allCategories.map((cat) => {
      if (str && cat.name.startsWith(str)) {
        filtered.push(cat);
      }
      return cat;
    });
    this.setState({ searchProduct: str, filteredCategories: filtered });
  }

  /**
   * @param {*} e élément cliqué sur le tableau de recherche
   * Envoie la catégorie séléctionnée dans l'élément parent via la méthode onSearchSelect
   */
  handleSelectItem(e) {
    let goodCat = {};
    for (let i = 0; i < this.state.filteredCategories.length; i++) {
      if (this.state.filteredCategories[i].name === e.target.textContent) {
        goodCat = this.state.filteredCategories[i];
        break;
      }
    }
    let selectCat = {
      category: goodCat,
    };
    this.props.onSearchSelect(selectCat);
    this.setState({ filteredCategories: [], searchProduct: "" });
  }

  render() {
    return (
      <tr>
        <th>
          <Input
            style={{ borderRadius: "25px", width: "60%", fontSize: "12px" }}
            bsSize="sm"
            placeholder="Ex: Ecran, Clavier..."
            type="search"
            value={this.state.searchProduct}
            onChange={this.handleChangeSearchProduct}
          />
          <div className="pac-container">
            {this.state.filteredCategories.length ? (
              this.state.filteredCategories.map((cat) => {
                return (
                  <div className="pac-item">
                    <span
                      className="pac-item-query"
                      onClick={this.handleSelectItem}
                    >
                      {cat.name}
                    </span>
                  </div>
                );
              })
            ) : (
              <div></div>
            )}
          </div>
        </th>

        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    );
  }
}

export default LigneTableauRecherche;
