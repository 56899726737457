import React from "react";
import RadioInput from "./radioInput";
import { Input, Form, FormGroup, Label } from "reactstrap";

class allConditionnements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      showDetailsVrac: false,
      checked: false,
      conditionningType: [
        { name: "Colis" },
        { name: "Palettes" },
        { name: "Vrac (IDF)" },
      ],
    };
    this.handleSaveChoices = this.handleSaveChoices.bind(this);
    this.onShowDetails = this.onShowDetails.bind(this);
    this.onShowDetailsVrac = this.onShowDetailsVrac.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
  }

  componentDidMount() {
    if (this.props.recapInfo) {
      let newConditionningType = [...this.state.conditionningType];
      newConditionningType.map((obj) => {
        obj.checked = this.props.recapInfo.conditionnement === obj.name;
        return obj;
      });
      if (this.props.recapInfo.conditionnement === "Palettes") {
        this.setState({
          conditionningType: newConditionningType,
          showDetails: true,
          checked: this.props.recapInfo.camion,
        });
      } else if (this.props.recapInfo.conditionnement === "Vrac (IDF)") {
        this.setState({
          conditionningType: newConditionningType,
          showDetailsVrac: true,
          checked: this.props.recapInfo.camion,
        });
      } else {
        this.setState({
          conditionningType: newConditionningType,
          checked: this.props.recapInfo.camion,
        });
      }
    }
  }

  /**
   * @param {*} obj bolean
   * Permet à la div de s'afficher pour renseigner le nombre de palettes
   */
  onShowDetails(obj) {
    this.setState({ showDetails: obj });
  }

  /**
   * @param {*} obj bolean
   * Permet à la div de s'afficher pour renseigner pour le vrac s'il y a un camion forcé ou non
   */
  onShowDetailsVrac(obj) {
    this.setState({ showDetailsVrac: obj });
  }

  /**
   * @param {*} obj objet contenant le choix de conditionnement
   * Renvoie vers la page option le consitionnement choisi via la méthode "saveObjetRecap"
   * Permet de retourver les informations de conditionnement si modification du devis
   */
  handleSaveChoices(obj) {
    this.props.saveObjetRecap(obj);
    if (this.state.conditionningType) {
      let newConditionningType = [...this.state.conditionningType];

      newConditionningType.map((elem) => {
        if (obj.param === elem.name) {
          elem.checked = true;
        } else {
          elem.checked = false;
        }
        return obj;
      });
    }
  }

  /**
   * @param {*} e nombre de palettes renseigné dans l'input
   * Renvoie vers la page option le nombre de palettes via la méthode "onAllConditionnement"
   */
  handleChange(e) {
    this.props.onAllConditionnement(e.target.value);
  }

  /**
   * @param {*} e bolean : séléction d'un camion forcé ou non
   * Renvoie vers la page option si le camion est à true ou false
   */
  handleCheckBoxClick(e) {
    let vracTruck = { type: "camion", param: e.target.checked };
    this.props.saveObjetRecap(vracTruck);
    this.setState({ checked: e.target.checked });
  }

  render() {
    return (
      <div>
        <RadioInput
          recapInfo={this.props.recapInfo}
          list={this.state.conditionningType}
          handleInputToTrue={this.onShowDetails}
          handleVracToTrue={this.onShowDetailsVrac}
          type={"conditionnement"}
          saveObjetRecap={this.handleSaveChoices}
          name="radio98"
        ></RadioInput>

        {this.state.showDetails ? (
          <div>
            <div className="inline" style={{ alignItems: "center" }}>
              <h4 className="h4 w-auto h-auto">Nombre de palettes :</h4>
              <Input
                size="sm"
                type="number"
                min={1}
                className="small"
                onChange={this.handleChange}
                value={this.props.nombrePalettes}
                style={{
                  borderRadius: "25px",
                  border: "none",
                  width: "20%",
                  marginLeft: "1rem",
                  marginBottom: "8px",
                }}
              ></Input>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {this.state.showDetailsVrac ? (
          <div>
            <div className="inline" style={{ alignItems: "center" }}>
              <Form>
                <FormGroup check inline style={{ paddingLeft: "20rem" }}>
                  <Input
                    onClick={this.handleCheckBoxClick}
                    checked={this.state.checked}
                    type="checkbox"
                  />
                  <Label check className="h4">
                    Camion Forcé
                  </Label>
                  <p style={{ fontSize: "13px" }}>
                    (à cocher si présence d'une imprimante pro ou d'une palette
                    dans la collecte)
                  </p>
                </FormGroup>
              </Form>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default allConditionnements;
