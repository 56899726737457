import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem, NavbarBrand, NavLink } from "reactstrap";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.State = {};
  }
  render() {
    return (
      <div>
        <Navbar className="navbar" light expand="md">
          <NavbarBrand style={{ paddingLeft: "1.5rem" }} href="/">
            <img
              src={require("../images/manutan.ico")}
              width="40"
              height="40"
              alt=""
            />
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#">Logout</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        <Nav vertical light expand="md" className="p-3 nav-vertical">
          <NavLink href="#">Faire une estimation</NavLink>
          <NavLink href="#">Rechercher une estimation</NavLink>
          <NavLink href="#">Changer des valeurs</NavLink>
        </Nav>
      </div>
    );
  }
}

export default Home;
