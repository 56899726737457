import React from "react";
import {
  Input,
  ListGroupItem,
  CardImg,
  Card,
  CardBody,
  Label,
} from "reactstrap";

class RadioInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
    this.handleSaveChoices = this.handleSaveChoices.bind(this);
  }

  /**
   * @param {*} obj bolean correspondant au clic sur palette ou vrac
   * Renvoie true si les palettes ou le vrac sont choisies et envoie cette information à allConditionnement
   * Déclanchement de la fonction handleSaveChoices
   */
  handleClick(obj) {
    if (this.props.handleInputToTrue) {
      this.props.handleInputToTrue(
        (obj.target.id === "oui" && obj.target.checked) ||
          (obj.target.id === "Palettes" && obj.target.checked)
      );
    }

    if (this.props.handleVracToTrue) {
      this.props.handleVracToTrue(
        (obj.target.id === "oui" && obj.target.checked) ||
          (obj.target.id === "Vrac (IDF)" && obj.target.checked)
      );
    }
    this.handleSaveChoices(obj);
  }

  /**
   * @param {*} obj bolean correspondant au clic sur palette ou vrac
   * Envoie vers l'élément parent l'objet objToUpdate avec le type choisi (conditionnement, rse, effacement certifié) et le param (bolean)
   */
  handleSaveChoices(obj) {
    let objToUpdate = {
      type: this.props.type,
      param: obj.target.id,
    };
    this.props.saveObjetRecap(objToUpdate);
  }

  render() {
    return (
      <div>
        <ListGroupItem className="align inline border-0 w-auto h-auto bg-neutral-100 ">
          {this.props.list.map((type, key) => {
            return (
              <Card
                id={key}
                className="align border-0 pl-2 bg-neutral-100 pr-11"
              >
                {this.props.type === "conditionnement" ? (
                  <CardImg
                    top
                    className="ct"
                    alt=""
                    src={require("../images/" + type.name + ".png")}
                    style={{ width: "30px" }}
                    onClick={this.handleClick}
                    id={type.name}
                    value={"input"}
                  />
                ) : (
                  ""
                )}
                <CardBody className="inline text-center">
                  <Input
                    className="pl-2"
                    value={"input"}
                    name={this.props.name}
                    type="radio"
                    id={type.name}
                    onClick={this.handleClick}
                    checked={type.checked ? type.checked : this.value}
                  />
                  <Label
                    className="pl-2"
                    onClick={this.handleClick}
                    id={type.name}
                    value={"input"}
                  >
                    {type.name}
                  </Label>
                </CardBody>
              </Card>
            );
          })}
        </ListGroupItem>
      </div>
    );
  }
}

export default RadioInput;
