import React from "react";
import "../app.css";
import { Button, Table } from "reactstrap";
import { connect } from "react-redux";
import { getPrices, saveEstimations } from "../redux/index";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";

export class TableauRecap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: this.props.tab,
      option: this.props.options,
      prices: [],
      parameter: [],
      transportPrice: "",
      bilanCertPrice: "",
      rsePrice: "",
      AsfPrice: 0,
      unitTransportPrice: this.props.prices,
      totalPrice: 0,
      priceAppear: true,
      navValue: 100,
      sousTotalCollTraitementPrice: 0,
      transportType: "",
      totalInventoryHours: 0,
      ttcPrice: 0,
    };

    this.timeConvert = this.timeConvert.bind(this);
    this.compileEstimation = this.compileEstimation.bind(this);

    // this.ttcPrice = this.ttcPrice.bind(this);
  }

  componentDidMount() {
    let transportType = "";
    if (this.props.options && this.props.options.conditionnement === "Colis") {
      transportType = "UPS";
    } else if (
      this.props.options &&
      this.props.options.conditionnement === "Palettes"
    ) {
      transportType = "Apia";
    } else {
      transportType = "MyTroopers";
    }

    const totalInfo = { tab: this.state.tab, option: this.state.option };
    this.props.getPrices(totalInfo);

    this.setState({
      transportType: transportType,
    });
  }

  componentDidUpdate() {
    
  }

  /**
   * @param {*} time temps d'inventaire en nombre décimal
   * Convertit le temps d'inventaire en h/min
   */
  timeConvert(time) {
    let hours = Math.floor(time);
    let minutes = (time - hours) * 60;
    let rminutes = Math.round(minutes);
    let returned_time = "";

    if (hours === 0) {
      returned_time = rminutes + " minutes";
    } else {
      returned_time = hours + " heure(s) et " + rminutes + " minutes";
    }
    return returned_time;
  }

  /**
   * Compile toutes les informations dans un objet doc afin d'être enregistré en BDD
   * Envoie l'objet vers le store
   */
  compileEstimation() {
    const doc = {
      items: this.state.tab,
      options: this.props.options,
      prices: this.props.prices.result.allBigPrices,
      totalCatPrices: this.state.AsfPrice,
      transportType: this.state.transportType,
      totalPrice: this.state.totalPrice,
    };
    if (doc) {
      this.props.saveEstimations(doc);
    }
  }

  transform_transport_price(price){
    if(price){
      let result = parseFloat(price.result.transportPrice.toFixed(2));
      return result;
    } else {
      return "";
    }
  }

  // ttcPrice() {
  //   if (this.props.prices.result.allBigPrices.RSEPrice) {
  //     let ttcWithoutRSE =
  //       (this.state.totalPrice -
  //         this.props.prices.result.allBigPrices.RSEPrice) /
  //       0.7;
  //     console.log(ttcWithoutRSE, "ttcwithoutRSE");
  //     let ttcPrice =
  //       ttcWithoutRSE + this.props.prices.result.allBigPrices.RSEPrice;
  //     this.setState({ ttcPrice: ttcPrice });
  //   }
  // }

  render() {
    return (
      <div>
        <div className="row mb-4 pt-3">
          <div className="col"></div>
          <div className="col-10">
            <Navbar navValue={this.state.navValue}></Navbar>
            <h1 className="h1">Devis Récapitulatif :</h1>
          </div>
          <div className="col"></div>
        </div>

        <div className="row mb-3 pt-4 bg-neutral-100">
          <div className="col"></div>
          <div className="col-10 mb-10" style={{ fontSize: "smaller" }}>
            <Table striped style={{ tableLayout: "fixed", marging: "0px" }}>
              <thead style={{ fontWeight: "bold" }}>
                <tr>
                  <th style={{ width: "30%", fontWeight: "bold" }}>
                    Catégorie de Coût
                  </th>
                  <td style={{ width: "10%" }}>Quantité</td>
                  <td style={{ width: "20%" }}>Estimation Poids</td>
                  <td style={{ width: "20%" }}>Temps de traitement</td>
                  <td style={{ width: "10%" }}>Prix HT</td>
                </tr>
              </thead>
              <tbody>
                {this.props.prices
                  ? this.props.prices.result.allBigPrices.perCatPrice.map((elem) => {
                      return (
                        <tr>
                          <th>{elem.displayName}</th>
                          <td>{elem.quantity}</td>
                          <td>
                            {(
                              (elem.averageWeight * elem.quantity) /
                              1000
                            ).toFixed(2)}{" "}
                            kg
                          </td>
                          <td>{this.timeConvert(elem.totalTime)}</td>
                          <td>{elem.perCategoryPrice.toFixed(2)} € </td>
                        </tr>
                      );
                    })
                  : ""}{" "}
                <tr>
                  <th>Forfait ASF</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    {this.props.prices
                      ? this.props.prices.result.allBigPrices.ASFPrice
                      : ""}{" "}
                    €
                  </td>
                </tr>{" "}
                <tr>
                  <th style={{ fontWeight: "bold", paddingLeft: "2rem" }}>
                    Total ASF
                  </th>
                  <th></th>
                  <th></th>
                  <td style={{ fontWeight: "bold" }}>
                    {this.props.prices
                      ? this.timeConvert(this.props.prices.result.totalInventoryHours)
                      : ""}{" "}
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {this.props.prices
                      ? Number(this.props.prices.result.AsfPrice.toFixed(2))
                      : ""}{" "}
                    €
                  </td>
                </tr>
                <tr>
                  <th>Transport</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td style={this.props.prices 
                    && this.props.options.conditionnement === "Vrac (IDF)" ? 
                    {backgroundColor:"grey"}: {}}>
                    {this.props.options.conditionnement === "Vrac (IDF)" ? "" : this.transform_transport_price(this.props.prices)+"€"}{" "}
                  </td>
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold", paddingLeft: "2rem" }}>
                    Sous total collecte et traitement
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td style={{ fontWeight: "bold" }}>
                    {this.props.prices
                      ? Number(this.props.prices.result.sousTotalCollTraitementPrice).toFixed(
                          2
                        )
                      : ""}{" "}
                    €
                  </td>
                </tr>
                <tr>
                  <th>Effacement Certifié</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    {this.props.prices
                      ? this.props.prices.result.allBigPrices.effCertPrice
                      : ""}{" "}
                    €
                  </td>
                </tr>
                <tr>
                  <th>RSE</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td>
                    {this.props.prices
                      ? this.props.prices.result.allBigPrices.RSEPrice
                      : ""}{" "}
                    {""}€
                  </td>
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold", paddingLeft: "2rem" }}>
                    Prix total HT :
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td style={{ fontWeight: "bold" }}>
                    {this.props.prices
                      ? Number(this.props.prices.result.totalPrice).toFixed(2)
                      : ""}{" "}
                    €
                  </td>
                </tr>{" "}
                <tr>
                  <th style={{ fontWeight: "bold", paddingLeft: "2rem" }}>
                    Prix total HT avec marge :
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <td style={{ fontWeight: "bold" }}>
                    {this.props.prices
                      ? Number(this.props.prices.result.totalPrice / 0.7 / 0.8).toFixed(2)
                      : ""}{" "}
                    €
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="col"></div>
        </div>
        {
          this.props.prices 
          && this.props.options.conditionnement === "Vrac (IDF)" ?
          <div className="row">
          <div className="col-1"></div>
          <div className="col-11">
            <div className="row" style={{display: "inline"}}>
              <div className="col-12">
                <table>
                  <tr>
                    <td className="pr-1"><div style={{width:"40px",height:"15px", backgroundColor:"grey"}}></div></td>
                    <td>Faire une demande d’estimation coût OPS au service opération pour estimer le coût du transport</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
          :
          ""
        }
        <div className="row mb-3 mt-1">
          <div className="col"></div>
          <div className="col-10 mb-1" style={{ textAlign: "center" }}>
            <Link to={"/options"}>
              <Button className="btnNextStep">Modifier le Devis</Button>{" "}
            </Link>
            <Button className="btnNextStep ml-1" href="/produits">
              Nouvelle estimation
            </Button>{" "}
            <Button
              className="btnNextStep ml-1"
              href="/"
              onClick={this.compileEstimation}
            >
              Enregistrer le Devis
            </Button>{" "}
          </div>
          <div className="col"></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tab: state.tab,
    options: state.options,
    prices: state.prices,
    parameter: state.parameter,
  };
}

export default connect(mapStateToProps, { getPrices, saveEstimations })(
  TableauRecap
);
