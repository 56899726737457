import axios from "axios";
import { url_backend } from "./constants";

const homeBackend = axios.create({
  baseURL: url_backend,
  headers: {
    "Content-Type": "application/json",
  },
});

export { homeBackend };
