const url_backend =
  process.env.NODE_ENV === "production"
    ? "https://api-quotation.zack.eco/"
    : "http://localhost:8000";
const adresse_region = "administrative_area_level_1";

module.exports = {
  url_backend: url_backend,
  adresse_region: adresse_region,
};
