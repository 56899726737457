import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Card,
  CardImg,
  CardBody,
  CardTitle,
} from "reactstrap";

class ElementListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedElement: this.props.selectedElement,
    };

    this.onClickCategory = this.onClickCategory.bind(this);
  }

  /**
   * @param {*} e element correspondant à la categorie choisie
   * Envoie à l'élement parent les informations (famille, categorie et id) de l'élément cliqué
   */
  onClickCategory(e) {
    this.props.onHandleClickedElement({
      family: this.props.family,
      id: e.target.id,
      category: this.props.categories[e.target.id],
    });
  }

  render() {
    const categories = this.props.categories
      .sort(function (a, b) {
        if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
          return -1;
        if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1;
        return 0;
      })
      .map((element, key) => {
        return (
          <ListGroupItem
            className="bg-neutral-100"
            tag="button"
            key={key}
            id={key}
            onClick={this.onClickCategory}
            cat={element}
            style={{
              border: "none",
              paddingTop: "5px",
              paddingBottom: "0",
              paddingLeft: "2rem",
            }}
          >
            {element.displayName}
          </ListGroupItem>
        );
      });

    return (
      <div>
        <ListGroup
          flush
          horizontal={false}
          style={{ alignItems: "flex-start" }}
        >
          <ListGroupItem
            key={"A"}
            className="bg-neutral-100"
            style={{ border: "0px" }}
          >
            <Card
              className="bg-neutral-100 box"
              style={{ alignItems: "center", border: "0px" }}
            >
              <CardImg
                top
                alt=""
                src={this.props.image}
                style={{
                  width: "25%",
                  paddingTop: "1.6rem",
                }}
              />
              <CardBody style={{ padding: "0.5rem" }}>
                <CardTitle
                  className="font-semibold"
                  style={{ alignItems: "center" }}
                >
                  {this.props.family}
                </CardTitle>
              </CardBody>
            </Card>
          </ListGroupItem>
          {categories}
        </ListGroup>
      </div>
    );
  }
}

export default ElementListing;
