import {
  GET_FAMILLIES,
  TRANSFER_TAB,
  SOCKET,
  TRANSFER_OPTIONS,
  GET_PRICES,
  GET_PARAMETER,
  SAVE_ESTIMATIONS,
  GET_LOGIN_INFO,
  GET_SIGN_UP_INFO,
} from "./types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_FAMILLIES:
      return { ...state, families: action.payload };
    case TRANSFER_TAB:
      return { ...state, tab: action.payload };
    case TRANSFER_OPTIONS:
      return { ...state, options: action.payload };
    case SOCKET:
      return { ...state, socket: action.payload };
    case GET_PRICES:
      return { ...state, prices: action.payload };
    case GET_PARAMETER:
      return { ...state, parameter: action.payload };
    case SAVE_ESTIMATIONS:
      return { ...state, estimates: action.payload };
    case GET_LOGIN_INFO:
      return { ...state, loginInfo: action.payload };
    case GET_SIGN_UP_INFO:
      return { ...state, signUpInfo: action.payload };

    default:
      return {};
  }
}
