import React from "react";
import "../app.css";
import { Button } from "reactstrap";

const Intro = () => {
  return (
    <div className="background">
      <h1 className="title" style={{ paddingLeft: "7%" }}>
        Bienvenue sur l'outil de pricing
      </h1>
      <div
        className="row justify-content-md-center"
        style={{ paddingTop: "10%" }}
      >
        <div className="col"></div>
        <div className="col-6">
          <Button className="btn3" href="/produits" style={{ border: "none" }}>
            Lancer une estimation
          </Button>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
};

export default Intro;
