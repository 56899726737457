import React from "react";
import { Table } from "reactstrap";
import LigneTableau from "./ligneTableau";
import LigneTableauRecheche from "./ligneTableauRecherche";

class Tableau extends React.Component {
  constructor(props) {
    super(props);

    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleRemoveLine = this.handleRemoveLine.bind(this);
    this.handleOnSearchSelect = this.handleOnSearchSelect.bind(this);
  }

  /**
   * @param {*} obj objet categorie contenant la quantité
   * Renvoie l'obj à la page produit via la méthode onQuantityChange
   **/
  handleQuantityChange(obj) {
    this.props.onQuantityChange(obj);
  }

  /**
   * @param {*} obj objet categorie séléctionnée
   * Renvoie l'obj à la page produit via la méthode onRemoveLine
   **/
  handleRemoveLine(obj) {
    this.props.onRemoveLine(obj);
  }

  /**
   * @param {*} obj objet categorie séléctionnée dans le tableau de recherche
   * Renvoie l'obj à la page produit via la méthode onSearchSelect
   **/
  handleOnSearchSelect(obj) {
    this.props.onSearchSelect(obj);
  }

  render() {
    return (
      <Table striped style={{ tableLayout: "fixed", marging: "0px" }}>
        <thead>
          <tr>
            <th style={{ width: "30%" }}>Produit</th>
            <td style={{ width: "10%" }}>Quantité</td>
            <td style={{ width: "20%" }}>Estimation Poids</td>
            <td style={{ width: "20%" }}>Temps de traitement</td>
            {this.props.priceAppear ? (
              <td style={{ width: "10%" }}>Prix HT</td>
            ) : (
              <td style={{ width: "10%" }}></td>
            )}
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
        <tbody>
          {this.props.displaySearchRow ? (
            <LigneTableauRecheche
              key={"LTR"}
              allCategories={this.props.allCategories}
              onSearchSelect={this.handleOnSearchSelect}
            ></LigneTableauRecheche>
          ) : (
            ""
          )}

          {this.props.categories.map((category, key) => {
            return (
              <LigneTableau
                name={category.category.displayName}
                id={key}
                quantity={category.category.quantity}
                category={category.category}
                onChangeQuantity={this.handleQuantityChange}
                onRemoveLine={this.handleRemoveLine}
              ></LigneTableau>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default Tableau;
