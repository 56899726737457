import React from "react";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import { socketAction } from "../redux/index";
import { adresse_region } from "../constants";

class Adresse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      adresse: [],
    };
    this.handleSaveChoices = this.handleSaveChoices.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onDistanceMessage = this.onDistanceMessage.bind(this);

    props.socket.on("rtAdresse", (message) => this.onMessage(message));
    props.socket.on("rtDefAdresse", (message) => this.onDetailMessage(message));
    props.socket.on("distance", (message) => this.onDistanceMessage(message));
  }

  componentDidMount() {
    if (this.props.recapInfo) {
      this.setState({ inputValue: this.props.recapInfo.adresseToPrint });
    }
  }

  /**
   * @param {*} message adresse récupérée de l'API
   * Modifie le state de "adresse" avec la donnée récupérée de l'API Google Adresse Autoplaces
   */
  onMessage(message) {
    let jsonedStr = JSON.parse(message);
    this.setState({ adresse: jsonedStr });
  }

  /**
   * @param {*} message détail de l'adresse récupérée de l'API
   * Envoie à l'élément parent des compléments d'information de l'adresse (région) et set le state d'"input value" pour envoyer la valeur à afficher
   */
  onDetailMessage(message) {
    let res = JSON.parse(message);
    let regionLongName = "";
    let adresseToPrint = "";

    if (res.result.address_components) {
      adresseToPrint = res.result.formatted_address;
      res.result.address_components.map((elem) => {
        if (elem.types[0] === adresse_region) {
          regionLongName = elem.long_name;
        }
        return regionLongName;
      });
      let adresse = {
        regionLongName: regionLongName,
        adresseToPrint: adresseToPrint,
      };

      this.props.onAdresse(adresse);
      this.setState({ inputValue: adresseToPrint });
    }
  }

  /**
   * @param {*} message distance entre ASF et l'adresse récupérée de l'API
   * Envoie à l'élément parent la distance entre ASF et l'adresse selectionnée
   */
  onDistanceMessage(message) {
    let res = JSON.parse(message);
    let adresse = {
      distance: res.rows[0].elements[0].distance.value,
    };
    this.props.onAdresse(adresse);
  }

  /**
   * @param {*} obj adresse marquée dans l'input
   * Une fois l'adresse sélectionnée, envoie de l'adresse au back pour avoir des détails sur la région et la distance
   */
  handleSaveChoices(obj) {
    this.props.socket.emit("adresseDef", { id: obj.target.id });
    this.props.socket.emit("distance", { id: obj.target.id });
    this.setState({ inputValue: obj.target.innerText, adresse: [] });
  }

  /**
   * @param {*} e adresse marquée dans l'input
   * Envoie vers le back au fur et à mesur de la recherche, l'adresse entrée dans l'input
   */
  handleChangeAutocomplete(e) {
    this.props.socket.emit("adresse", { str: e.target.value });
    this.setState({ inputValue: e.target.value });
  }

  render() {
    return (
      <div className="pl-9" style={{ paddingTop: "1.3rem", width: "24%" }}>
        <Input
          placeholder="Ex: 4 rue des Lilas, 64600 Anglet"
          type="search"
          value={this.state.inputValue}
          onChange={this.handleChangeAutocomplete}
          style={{
            borderRadius: "25px",
            border: "none",
            width: "180%",
            fontSize: "13px",
          }}
        />
        <div className="pac-container">
          {!this.state.adresse.predictions ? (
            <div id="cul"></div>
          ) : (
            this.state.adresse.predictions.map((adresse) => {
              return (
                <div className="pac-item" style={{ width: "500px" }}>
                  <span class="pac-icon pac-icon-marker"></span>
                  <span
                    id={adresse.place_id}
                    className="pac-item-query"
                    onClick={this.handleSaveChoices}
                  >
                    {adresse.description}
                  </span>
                </div>
              );
            })
          )}{" "}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    socket: state.socket,
    recapInfo: state.options,
  };
};

export default connect(mapStateToProps, { socketAction })(Adresse);
