import React from "react";
import { Button, Alert } from "reactstrap";
import { connect } from "react-redux";
import "../app.css";
import { Link } from "react-router-dom";

import TitreEtInput from "../components/titreEtInput";
import Adresse from "../components/adresse";
import AllConditionnements from "../components/allConditionnements";
import EffacementCertifie from "../components/effacementCertifie";
import BilanRse from "../components/bilanRse";
import Navbar from "../components/navbar";
import { transferOptions, transferTab, socketAction } from "../redux/index";

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titleAndInputTab: [
        { name: "Conditionnement : ", component: AllConditionnements },
        { name: "Adresse du client : ", component: Adresse },
        { name: "Effacement certifié : ", component: EffacementCertifie },
        { name: "Bilan RSE : ", component: BilanRse },
      ],
      recapInfo: {},
      tab: this.props.tab,
      adv_Nomb_Cert: 0,
      visible: false,
      nav_Value: 66,
      adv_Nomb_RSE: 0,
    };
    this.handleSaveChoices = this.handleSaveChoices.bind(this);
    this.handleElementCertifie = this.handleElementCertifie.bind(this);
    this.handleConditionnement = this.handleConditionnement.bind(this);
    this.handleClickPage = this.handleClickPage.bind(this);
    this.handleAdresse = this.handleAdresse.bind(this);
  }

  componentDidMount() {
    if (this.state.tab && this.state.tab.length > 0) {
      let nombCertif = 0;
      let nombRSE = 0;
      let totalweight = 0;
      this.state.tab.map((elem) => {
        nombCertif =
          nombCertif + elem.category.certifiable * elem.category.quantity;
        totalweight =
          totalweight + elem.category.averageWeight * elem.category.quantity;
        return { nombCertif: nombCertif, totalweight: totalweight };
      });

      if (totalweight < 100000) {
        nombRSE = 50;
      } else if (100000 <= totalweight && totalweight < 200000) {
        nombRSE = 100;
      } else if (200000 <= totalweight && totalweight < 500000) {
        nombRSE = 200;
      } else if (totalweight > 500000) {
        nombRSE = 300;
      }
      if (this.props.recapInfo && !this.state.recapInfo.conditionnement) {
        this.setState({
          adv_Nomb_Cert: nombCertif,
          adv_Nomb_RSE: nombRSE,
          recapInfo: this.props.recapInfo,
        });
      } else {
        this.setState({ adv_Nomb_Cert: nombCertif, adv_Nomb_RSE: nombRSE });
      }
    }
  }

  /**
   * @param {*} obj objet correspondant à l'option choisie (conditionnement, Bilan RSE, Effacement Certifié). Structure : {
      type: string,
      param: bolean,
    }
   * Crée un nouvel objet regroupant toutes les options choisies. Structure : recapInfo = {
    type (ex: conditionnement) : bolean;
    ...
   }
   Ajoute le nombre d'éléments à certifier dans l'objet recapInfo
   */
  handleSaveChoices(obj) {
    let newRecapInfo = this.state.recapInfo;
    newRecapInfo[obj.type] = obj.param;
    if (
      obj.type === "effacement" &&
      obj.param === "non" &&
      newRecapInfo.nombreElementCertifie
    ) {
      newRecapInfo.nombreElementCertifie = "";
    } else if (
      obj.type === "effacement" &&
      obj.param === "oui" &&
      newRecapInfo.nombreElementCertifie === undefined
    ) {
      newRecapInfo.nombreElementCertifie = this.state.adv_Nomb_Cert;
    }
    this.setState({ recapInfo: newRecapInfo });
  }

  /**
   * @param {*} nombre nombre d'éléments à certifier
   * Modifie l'objet recapInfo avec la valeur d'éléments à certifier rensignées dans l'input
   */
  handleElementCertifie(nombre) {
    let newRecapInfo = { ...this.state.recapInfo };
    newRecapInfo.nombreElementCertifie = nombre;
    this.setState({ recapInfo: newRecapInfo });
  }

  /**
   * @param {*} adresse objet contenant la région choisie. Structure : adresse = {
        regionLongName: string,
        adresseToPrint: string,
      }
   * Modifie l'objet recapInfo avec la region et la région à afficher dans l'input
   */
  handleAdresse(adresse) {
    let newRecapInfo = { ...this.state.recapInfo };
    if (adresse.regionLongName && adresse.adresseToPrint) {
      newRecapInfo.region = adresse.regionLongName;
      newRecapInfo.adresseToPrint = adresse.adresseToPrint;
    }
    if (adresse.distance) {
      newRecapInfo.distance = adresse.distance;
    }

    this.setState({ recapInfo: newRecapInfo });
  }

  /**
   * @param {*} nombre nombre de palettes
   * Modifie l'objet recapInfo avec le nombre de palettes renseigné dans l'input
   */
  handleConditionnement(nombre) {
    let newRecapInfo = { ...this.state.recapInfo };
    newRecapInfo.nombrePalettes = nombre;
    this.setState({ recapInfo: newRecapInfo });
  }

  /**
   * @param {*} e evenement
   * Empèche de passer à la page suivante si tous les champs ne sont pas renseignés.
   * Fait apparaitre et disparaitre une alerte
   * Envoie dans le store l'objet recapInfo
   */
  handleClickPage(e) {
    let visible = false;
    let errorMessage = "";
    if (
      !this.state.recapInfo ||
      (this.state.recapInfo && !this.state.recapInfo.conditionnement) ||
      !this.state.recapInfo.adresseToPrint ||
      !this.state.recapInfo.bilan ||
      !this.state.recapInfo.effacement
    ) {
      e.preventDefault();
      visible = true;
      errorMessage = "Certains champs sont vides";
    }

    this.setState({
      visible: visible,
      errorMessage: errorMessage,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
      });
    }, 2000);
    this.props.transferOptions(this.state.recapInfo);
  }

  render() {
    return (
      <div>
        <div className="row  pt-2 ">
          <div className="col"></div>
          <div className="col-10 mb-1">
            <Navbar navValue={this.state.nav_Value}></Navbar>
            <h1 className="h1">
              Type de conditionnement et options choisies :
            </h1>
          </div>
          <div className="col"></div>
        </div>
        <div className="row bg-neutral-100 pt-5 ">
          <div className="col"></div>
          <div className="col-10 ">
            {this.state.titleAndInputTab.map((elem, key) => {
              return (
                <TitreEtInput
                  onElementCertifie={this.handleElementCertifie}
                  onAdresse={this.handleAdresse}
                  onAllConditionnement={this.handleConditionnement}
                  saveObjetRecap={this.handleSaveChoices}
                  className={"row"}
                  name={elem.name}
                  id={key}
                  component={elem.component}
                  nombreElementCertifie={
                    this.state.recapInfo.nombreElementCertifie
                  }
                  nombreAdvElementCert={this.state.adv_Nomb_Cert}
                  nombreAdvRSE={this.state.adv_Nomb_RSE}
                  nombrePalettes={this.state.recapInfo.nombrePalettes}
                  recapInfo={this.props.recapInfo}
                ></TitreEtInput>
              );
            })}
          </div>
          <div className="col"></div>
        </div>

        <div className="row mb-3">
          <div className="col"></div>
          <div className="col-10 mt-2" style={{ textAlign: "center" }}>
            <Alert
              className="alert"
              color="info"
              isOpen={this.state.visible}
              toggle={this.onDismiss}
            >
              {this.state.errorMessage}
            </Alert>
            <Link to={"/produits"}>
              <Button className="btnNextStep mb-5 mt-2">Retour</Button>
            </Link>{" "}
            <Link to={"/tableauRecap"} onClick={this.handleClickPage}>
              <Button className="btnNextStep mb-5 mt-2">
                Passer à l'étape suivante
              </Button>
            </Link>
          </div>
          <div className="col"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tab: state.tab,
    recapInfo: state.options,
  };
};

export default connect(mapStateToProps, {
  transferOptions,
  transferTab,
  socketAction,
})(Options);
