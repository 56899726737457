import React from "react";
import "./app.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Intro from "./pages/intro";
import Options from "./pages/options";
import Produits from "./pages/produits";
import TableauRecap from "./pages/tableauRecap";
// import LoginPage from "./pages/login";
import Home from "./pages/home";
// import SignUp from "./pages/signup";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Intro />{" "}
          </Route>
          <Route path="/home">
            <Home />{" "}
          </Route>
          {/* <Route path="/login">
            <LoginPage />{" "} */}
          {/* </Route> */}
          {/* <Route path="/signup">
            <SignUp />{" "}
          </Route> */}
          <Route path="/produits">
            <Produits />{" "}
          </Route>
          <Route path="/options">
            <Options />{" "}
          </Route>
          <Route path="/tableauRecap">
            <TableauRecap />{" "}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
