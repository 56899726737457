import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import RadioInput from "./radioInput";

class EffacementCertifie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{ name: "oui" }, { name: "non" }],
      showDetails: false,
      compteToZero: "",
      checked: false,
    };
    this.onShowDetails = this.onShowDetails.bind(this);
    this.handleSaveChoices = this.handleSaveChoices.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
  }

  componentDidMount() {
    if (this.props.recapInfo) {
      let newlist = [...this.state.list];
      newlist.map((obj) => {
        obj.checked = this.props.recapInfo.effacement === obj.name;
        return obj;
      });
      if (this.props.recapInfo.effacement === "oui") {
        if (this.props.recapInfo.nombreElementCertifie === "") {
          this.setState({ list: newlist, showDetails: true, checked: true });
        }
        this.setState({ list: newlist, showDetails: true });
      } else {
        this.setState({ list: newlist });
      }
    }
  }

  /**
   * @param {*} obj bolean renvoyant true si l'effacement certifié est choisi
   * Met à jour à true l'état de showdetails afin d'afficher la div du détail du nombre d'éléments à certifier
   */
  onShowDetails(obj) {
    this.setState({ showDetails: obj });
  }

  /**
   * @param {*} obj objet contenant le choix d'un effacement certifié ou non
   * Renvoie vers la page option si l'effacement certifié est choisi via la méthode "saveObjetRecap"
   * Permet de retourver les informations d'effacement certifié si retour sur cette page (modification du devis)
   */
  handleSaveChoices(obj) {
    this.props.saveObjetRecap(obj);
    if (this.state.list) {
      let newList = [...this.state.list];

      newList.map((elem) => {
        if (obj.param === elem.name) {
          elem.checked = true;
        } else {
          elem.checked = false;
        }
        return obj;
      });
    }
  }

  /**
   * @param {*} e nombre correspondant au nombre d'éléments à certifier rentré par l'utilisateur
   * Renvoie vers la page option le nombre choisi pour l'enregistrer dans objetRecap
   */
  handleChange(e) {
    this.props.onElementCertifie(e.target.value);
  }

  /**
   * @param {*} e bolean correspondant si la case "nombre d'éléments à certifier inconnu pour le moment" est cliquée
   * Renvoie vers la page option le nombre choisi pour l'enregistrer dans objetRecap
   */
  handleCheckBoxClick(e) {
    if (e.target.checked) {
      this.props.onElementCertifie("");
    }
    this.setState({ checked: e.target.checked });
  }

  render() {
    return (
      <div>
        <RadioInput
          list={this.state.list}
          showDetails={this.state.showDetails}
          handleInputToTrue={this.onShowDetails}
          type={"effacement"}
          saveObjetRecap={this.handleSaveChoices}
          name="radio2"
          recapInfo={this.props.recapInfo}
        ></RadioInput>
        {this.state.showDetails ? (
          <div>
            <h4 className="h4">
              Nombre d'appareils éligibles à l'effacement certifié :{" "}
              {this.props.nombreAdvElementCert}
            </h4>{" "}
            <div className="inline" style={{ alignItems: "center" }}>
              <h4 className="h4 w-auto h-auto">
                Nombre d'appareils à effacer :
              </h4>
              <Input
                bsSize="sm"
                type="number"
                min={0}
                className="small"
                onChange={this.handleChange}
                defaultValue={this.props.nombreAdvElementCert}
                value={this.props.nombreElementCertifie}
                disabled={this.state.checked}
                style={{
                  borderRadius: "25px",
                  border: "none",
                  width: "20%",
                  marginLeft: "1rem",
                  marginBottom: "8px",
                  height: "auto",
                }}
              ></Input>
            </div>
            <div>
              <Form>
                <FormGroup check inline>
                  <Input
                    onClick={this.handleCheckBoxClick}
                    checked={this.state.checked}
                    type="checkbox"
                  />
                  <Label check className="h4">
                    Quantité inconnue pour le moment
                  </Label>
                </FormGroup>
              </Form>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default EffacementCertifie;
