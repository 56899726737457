import React from 'react';
import { connect } from "react-redux";
import  { socketAction } from '../redux/index';
import { url_backend } from "../constants";
const io = require('socket.io-client');

class Base extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        if(!this.props.socket || !this.props.socket.connected){
            let socket = io(url_backend);
            this.props.socketAction(socket);
        }
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        socket : state.socket
    };
}

export default connect(mapStateToProps, {socketAction} )(Base); 