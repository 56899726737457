import React from "react";
import "../app.css";
import { Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Tableau from "../components/tableau";
import Listing from "../components/listing";
import { getFamilies, transferTab } from "../redux/index";
import Navbar from "../components/navbar";

class Produits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
      inputValue: "",
      isHovered: false,
      families: [],
      tab: [],
      keyNumber: "",
      searchCategory: [],
      reduxState: {},
      priceAppear: false,
      visible: false,
      navValue: 33,
    };

    this.handleFamilySelected = this.handleFamilySelected.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleRemoveLine = this.handleRemoveLine.bind(this);
    this.getAllCategorySelectable = this.getAllCategorySelectable.bind(this);
    this.handleClickPage = this.handleClickPage.bind(this);
  }

  componentDidMount() {
    this.props.getFamilies();
    if (this.props.tab) {
      this.setState({ tab: this.props.tab });
    }
  }

  componentDidUpdate() {
    if (this.props.families && this.state.families.length === 0) {
      let searchCat = this.getAllCategorySelectable(this.props.families);
      this.setState({
        families: this.props.families,
        searchCategory: searchCat,
      });
    }
  }

  /**
   * @param {*} elem objet reçu de la page listing. Structure {
      family: string,
      id: id,
      category: obj,
    }
   * Si l'élément n'est pas déjà présent dans le tab, enregistre l'élément dans le tab
*/
  handleFamilySelected(elem) {
    let alreadyPresent = false;

    for (let y = 0; y < this.state.tab.length; y++) {
      if (this.state.tab[y].category.name === elem.category.name) {
        alreadyPresent = true;
        break;
      }
    }

    if (!alreadyPresent) {
      let newSearchCategories = this.state.searchCategory.filter(
        (a) => a.name !== elem.category.name
      );

      let newElem = {
        id: elem.id,
        family: elem.family,
        category: {
          _id: elem.category._id,
          quantity: 1,
          name: elem.category.name,
          displayName: elem.category.displayName,
          averageWeight: elem.category.averageWeight,
          averageInventoryTime: elem.category.averageInventoryTime,
          certifiable: elem.category.certifiable,
          perCategoryPrice: "",
          averageVolume: elem.category.averageVolume,
          inBox: elem.category.inBox,
          category_3: elem.category.category_3,
          coeff: elem.category.coeff,
          totalTime: elem.category.averageInventoryTime,
        },
      };

      this.setState({
        tab: [...this.state.tab, newElem],
        searchCategory: newSearchCategories,
      });
    }
  }

  /**
   * @param {*} obj objet . Structure {
      category: {
        id : id
        name : string
        ...
        quantity : number
      }
    }
   * Si l'élément est déjà présent dans le tab, met à jour la quantité de la catégorie séléctionnée et met à jour le temps de traitement
*/
  handleQuantityChange(obj) {
    const nextTab = this.state.tab.map((elem) => {
      if (elem.category._id === obj._id && elem.category.category_3 === false) {
        return {
          ...elem,
          category: {
            ...elem.category,
            quantity: obj.quantity,
            totalTime: obj.quantity * elem.category.averageInventoryTime,
          },
        };
      } else if (
        elem.category._id === obj._id &&
        elem.category.category_3 === true
      ) {
        return {
          ...elem,
          category: {
            ...elem.category,
            quantity: obj.quantity,
            totalTime: elem.category.averageInventoryTime,
          },
        };
      } else {
        return elem;
      }
    });
    this.setState({ tab: nextTab });
  }

  /**
   * @param {*} obj objet. Structure {
      category: {
        id : id
        name : string
        ...
        quantity : number
      }
    }
   * Retire la catégorie du tab
*/
  handleRemoveLine(obj) {
    const nextTab = this.state.tab.filter((a) => a.category._id !== obj._id);
    this.setState({
      tab: nextTab,
      searchCategory: [...this.state.searchCategory, obj],
    });
  }

  /**
   * @param {*} tab tableau d'objets. Structure : [ {
      family: string,
      id: id,
      category: obj,
    }]
   * Crée un tableau rassemblant toutes les catégories
*/
  getAllCategorySelectable(tab) {
    let allCategories = [];
    tab.map((fam) => {
      if (fam.name !== "Les plus courants")
        allCategories = allCategories.concat(fam.categories);
      return fam;
    });
    return allCategories;
  }

  /**
   * @param {*} e evenement
   * Empèche de passer à la page suivante si tous les champs ne sont pas renseignés.
   * Fait apparaitre et disparaitre une alerte
   * Envoie dans le store l'objet tab
   */
  handleClickPage(e) {
    if (this.state.tab === null || this.state.tab.length === 0) {
      e.preventDefault();
      this.setState({ visible: true });
      setTimeout(() => {
        this.setState({
          visible: false,
        });
      }, 2000);
    }
    this.props.transferTab(this.state.tab);
  }

  render() {
    return (
      <div>
        <div className="row mb-1 ">
          <div className="col"></div>

          <div
            className="col-10 pt-1"
            style={{
              placeItems: "flex-start",

              fontSize: "smaller",
            }}
          >
            {" "}
            <Navbar navValue={this.state.navValue}></Navbar>
            <h1 className="h1">Séléctionnez vos équipements :</h1>
          </div>
          <div className="col"></div>
        </div>
        <div
          className="row mb-3 bg-neutral-100"
          style={{
            placeItems: "flex-start",
            borderTop: "15rem",
            fontSize: "smaller",
          }}
        >
          <div className="col"></div>

          {!this.state.families ? (
            ""
          ) : (
            <div className="col-10 pt-1 ">
              <Listing
                families={this.state.families}
                onHandleClickedElement={this.handleFamilySelected}
              ></Listing>
            </div>
          )}

          <div className="col"></div>
        </div>

        <div className="row">
          <div className="col"></div>
          <div className="col-10" style={{ fontSize: "smaller" }}>
            <Tableau
              displaySearchRow={true}
              categories={this.state.tab}
              onQuantityChange={this.handleQuantityChange}
              onRemoveLine={this.handleRemoveLine}
              allCategories={this.state.searchCategory}
              onSearchSelect={this.handleFamilySelected}
              priceAppear={this.state.priceAppear}
            ></Tableau>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col"></div>
          <div className="col-10" style={{ textAlign: "center" }}>
            <Alert color="warning" isOpen={this.state.visible} fade>
              Aucune catégorie séléctionnée
            </Alert>

            <Link to={"/options"} onClick={this.handleClickPage}>
              <Button className="btnNextStep mb-5" onClick={null}>
                Passer à l'étape suivante
              </Button>
            </Link>
          </div>

          <div className="col"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    families: state.families,
    socket: state.socket,
    tab: state.tab,
  };
};

export default connect(mapStateToProps, { getFamilies, transferTab })(Produits);
