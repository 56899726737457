import React from "react";
import RadioInput from "./radioInput";

class BilanRse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{ name: "oui" }, { name: "non" }],
      firmList: [
        { name: "50 €" },
        { name: "100 €" },
        { name: "200 €" },
        { name: "300 €" },
      ],
      showDetails: false,
    };
    this.onShowDetails = this.onShowDetails.bind(this);
    this.handleSaveChoices = this.handleSaveChoices.bind(this);
  }

  componentDidMount() {
    if (this.props.recapInfo) {
      let newlist = [...this.state.list];
      let newFirmList = [...this.state.firmList];
      newlist.map((obj) => {
        obj.checked = this.props.recapInfo.bilan === obj.name;
        return obj;
      });
      newFirmList.map((elem) => {
        elem.checked = this.props.recapInfo.categorieEntreprise === elem.name;
        return elem;
      });
      if (this.props.recapInfo.bilan === "oui") {
        this.setState({
          list: newlist,
          firmList: newFirmList,
          showDetails: true,
        });
      } else {
        this.setState({ list: newlist, firmList: newFirmList });
      }
    }
  }

  /**
   * @param {*} obj bolean renvoyant true si le bilan RSE est choisi
   * Met à jour à true l'état de showdetails afin d'afficher la div des détails de prix RSE
   */
  onShowDetails(obj) {
    this.setState({ showDetails: obj });
  }

  /**
   * @param {*} obj obj renvoyant l'information si le bilan RSE est choisi
   * Renvoie vers la page option les informations séléctionnées pour le bilan RSE (oui/non, prix)
   * Permet de retrouver les informations choisies pour le bilan RSE lorsque l'on retourne sur la page option
   */
  handleSaveChoices(obj) {
    this.props.saveObjetRecap(obj);
    if (this.state.list && obj.type === "bilan") {
      let newList = [...this.state.list];

      newList.map((elem) => {
        if (obj.param === elem.name) {
          elem.checked = true;
        } else {
          elem.checked = false;
        }
        return obj;
      });
    }
    if (this.state.firmList && obj.type === "categorieEntreprise") {
      let newFirmList = [...this.state.firmList];

      newFirmList.map((elem) => {
        if (obj.param === elem.name) {
          elem.checked = true;
        } else {
          elem.checked = false;
        }
        return obj;
      });
    }
  }

  render() {
    return (
      <div horizontal>
        <RadioInput
          list={this.state.list}
          showDetails={this.state.showDetails}
          handleInputToTrue={this.onShowDetails}
          type={"bilan"}
          saveObjetRecap={this.handleSaveChoices}
          name="radio"
          recapInfo={this.props.recapInfo}
        ></RadioInput>
        {this.state.showDetails ? (
          <div>
            <h4 className="h4 w-auto h-auto">
              Recommandé : {this.props.nombreAdvRSE} €
            </h4>
            <RadioInput
              list={this.state.firmList}
              handleInputToTrue={null}
              type={"categorieEntreprise"}
              saveObjetRecap={this.handleSaveChoices}
              name="radio1"
            ></RadioInput>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default BilanRse;
