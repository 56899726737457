import ApiService from "../services/apiService";

import {
  GET_FAMILLIES,
  TRANSFER_TAB,
  SOCKET,
  TRANSFER_OPTIONS,
  GET_PRICES,
  GET_PARAMETER,
  SAVE_ESTIMATIONS,
  GET_LOGIN_INFO,
  GET_SIGN_UP_INFO,
} from "./types";

export function getFamilies() {
  return async function (dispatch) {
    const response = await ApiService.getApiFamilies();
    dispatch({ type: GET_FAMILLIES, payload: response.data.families });
  };
}

export function socketAction(socket) {
  return {
    type: SOCKET,
    payload: socket,
  };
}

export function transferTab(tab) {
  return {
    type: TRANSFER_TAB,
    payload: tab,
  };
}

export function transferOptions(options) {
  return {
    type: TRANSFER_OPTIONS,
    payload: options,
  };
}

export function getPrices(totalInfo) {
  return async function (dispatch) {
    const response = await ApiService.getTransportPrice(totalInfo);
    dispatch({ type: GET_PRICES, payload: response.data });
  };
}

export function saveEstimations(doc) {
  return async function (dispatch) {
    const response = await ApiService.saveEstimations(doc);
    dispatch({ type: SAVE_ESTIMATIONS, payload: response.data });
  };
}

export function getParameter() {
  return async function (dispatch) {
    const response = await ApiService.getParameter();
    dispatch({ type: GET_PARAMETER, payload: response.data });
  };
}

export function getLoginInfo() {
  return async function (dispatch) {
    const response = await ApiService.getLoginInfo();
    dispatch({ type: GET_LOGIN_INFO, payload: response.data });
  };
}

export function getSignUpInfo(doc) {
  return async function (dispatch) {
    const response = await ApiService.getSignUpInfo(doc);
    dispatch({ type: GET_SIGN_UP_INFO, payload: response.data });
  };
}
