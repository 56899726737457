import { homeBackend } from "../http-common";

class ApiService {
  getApiFamilies() {
    return homeBackend.get("/families");
  }

  getParameter() {
    return homeBackend.get("/parameter");
  }

  getTransportPrice(totalInfo) {
    return homeBackend.post("/transportPrice", totalInfo);
  }

  saveEstimations(estimates) {
    return homeBackend.post("/estimates", estimates);
  }

  getLoginInfo(loginInfo) {
    return homeBackend.post("/login", loginInfo);
  }

  getSignUpInfo(signUpInfo) {
    return homeBackend.post("/signup", signUpInfo);
  }
}

export default new ApiService();
