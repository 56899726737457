import React, { Component } from "react";
import { Progress } from "reactstrap";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div style={{ alignItems: "center", paddingTop: "1rem" }}>
        <Progress
          style={{
            height: "3px",
          }}
          color="secondary"
          value={this.props.navValue}
        />
      </div>
    );
  }
}

export default Navbar;
